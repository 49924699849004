@import '../../styles/themeVariables.scss';

.about-container {
  min-height: 758px;
  margin: 8px 0;
  padding: 20px 30px;
  font-size: 18px;
  line-height: 2;
  @include themify($themes) {
    color: themed('textPrimary');
    background-color: themed('bgSecondary');
    border: 1px solid themed('border');
  }
  border-radius: 5px;

  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }

  @media only screen and (max-width: 499px) {
    margin: 8px;
  }

  .about {
    margin-bottom: 30px;

    .important-text {
      display: inline;
      font-size: 26px;
      font-weight: 700;
      @include themify($themes) {
        color: themed('main');
      }

      @media only screen and (max-width: 768px) {
        font-size: 20px;
      }
    }

    a {
      font-weight: 500;
      @include themify($themes) {
        color: themed('main');
      }
      text-decoration: inherit;
    }
  }

  .details {
    .title {
      font-size: 22px;
      font-weight: 500;
      @include themify($themes) {
        color: themed('main');
      }

      @media only screen and (max-width: 768px) {
        font-size: 16px;
      }
    }

    .bold-text {
      font-weight: 500;
      @include themify($themes) {
        color: themed('main');
      }
    }
  }
}
