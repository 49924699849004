@import '../../../styles/themeVariables.scss';

.report-contracts {
  height: 665px;
  padding-top: 8px;
  @include themify($themes) {
    background-color: themed('bgSecondary');
    border: 1px solid themed('border');
  }
  border-radius: 5px;

  @media only screen and (max-width: 599px) {
    margin: 0 8px;
  }

  @include themify($themes) {
    .contracts-row-new {
      .MuiDataGrid-cell:first-child {
        border-left: 8px solid themed('new');
      }
    }

    .contracts-row-old {
      .MuiDataGrid-cell:first-child {
        border-left: 8px solid themed('old');
      }
    }
  }

  .contracts-row-other {
    .MuiDataGrid-cell:first-child {
      border-left: 8px solid #636366;
    }
  }

  .contract-ineligible {
    div {
      opacity: 50%;
    }
  }

  .subject-icon {
    margin-right: 8px;
  }

  .contract-location {
    cursor: pointer;
  }

  &.simple {
    padding-top: 0;
  }

  .type-badge {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 74px;
    color: #fff;
    font-weight: 700;
    background-color: #636366;
    border-radius: 15px;

    @include themify($themes) {
      &.NG {
        width: 48px;
        background-color: themed('new');
      }

      &.SG {
        width: 48px;
        background-color: themed('old');
      }
    }
  }

  .info-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 70px;
    @include themify($themes) {
      color: themed('textPrimary');
      background-color: themed('bgPrimary');
    }
    border-radius: 18px;
  }

  .legend-right {
    margin-left: auto;
    margin-right: 5px;
    @include themify($themes) {
      color: themed('main');
    }

    label {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.report-contracts-legend {
  font-size: 14px;
  font-weight: 400;

  .legend-item {
    display: flex;
    align-items: center;
    padding: 5px;

    svg {
      padding-right: 5px;
    }

    &.type {
      margin: 5px;
      justify-content: center;
    }

    &.other {
      background-color: #636366;
    }
  }
}

.MuiFormControl-root {
  @media only screen and (max-width: 599px) {
    &.MuiDataGrid-filterFormColumnInput {
      width: 90px;
    }

    &.MuiDataGrid-filterFormOperatorInput {
      width: 110px;
    }

    &.MuiDataGrid-filterFormValueInput {
      width: 130px;
    }
  }
}
