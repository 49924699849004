@import '../../../styles/themeVariables.scss';

.report-map {
  position: relative;
  display: flex;

  @media only screen and (max-width: 899px) {
    flex-direction: column;
    height: auto;
    margin: 0 8px;
  }

  .map-filters {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;
    padding: 4px 8px 0;
    border-radius: 5px;
    @include themify($themes) {
      background-color: themed('bgSecondary');
      border: 1px solid themed('border');
    }

    @media only screen and (max-width: 899px) {
      flex-direction: row;
      flex-wrap: wrap;
    }

    .MuiFormControl-root {
      margin-top: 0;
      margin-bottom: 12px;
    }

    form {
      display: flex;

      .MuiFormControl-root {
        flex: 1;
        margin: 0 5px 12px;
      }
    }

    .filters-bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 8px 0;

      @media only screen and (max-width: 899px) {
        flex: 100%;
      }
    }

    .filters-arrow {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      min-width: 106px;
      text-decoration: underline;
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;
      @include themify($themes) {
        color: themed('main');
      }
    }

    .filter-title {
      margin: 4px 0 8px;
      font-size: 16px;
      text-align: center;
      @include themify($themes) {
        color: themed('textPrimary');
      }
    }
  }

  .map-filters-mobile {
    display: none;

    @media only screen and (max-width: 899px) {
      display: block;
    }
  }

  .map-container {
    flex: 1;
    position: relative;
    margin-right: 8px;
    @include themify($themes) {
      border: 1px solid themed('border');
    }
    border-radius: 5px;

    @media only screen and (max-width: 899px) {
      margin-right: 0;
    }

    &.hide-map-mobile {
      @media only screen and (max-width: 899px) {
        position: absolute;
        opacity: 0;
        pointer-events: none;
      }
    }

    .leaflet-container {
      font-family: inherit;
    }

    .map-overlay {
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      display: flex;
      justify-content: center;
      z-index: 1000;
      min-width: max-content;
      margin-top: 10px;
      color: #000;
      background-color: #fff;
      border: 2px solid rgba(0,0,0,0.2);
      border-radius: 8px;

      @media only screen and (max-width: 899px) {
        justify-content: space-around;
      }

      .contracts-number {
        display: flex;
        align-items: center;
        padding: 5px 10px;

        @media only screen and (max-width: 899px) {
          font-size: 10px;
          padding: 3px 6px;
        }

        span {
          font-size: 18px;
          font-weight: 500;

          @media only screen and (max-width: 899px) {
            font-size: 12px;
          }
        }
      }
    }

    .remove-polygon {
      position: absolute;
      right: 10px;
      top: 90px;
      width: 158px;
      height: 30px;
      z-index: 1000;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 0 4px 0 8px;
      border: 2px solid rgba(0,0,0,0.2);
      border-radius: 4px;
      background-color: #fff;
      background-clip: padding-box;
      cursor: pointer;

      &:hover {
        background-color: #f4f4f4;
      }

      span {
        padding-right: 5px;
      }

      svg {
        @include themify($themes) {
          color: themed('redLight');
        }
      }
    }
  }

  .map-right-section {
    display: flex;
    flex-direction: column;
    width: 320px;
    height: 722px;

    @media only screen and (max-width: 899px) {
      width: 100%;

      &.hide-contracts-mobile {
        position: absolute;
        opacity: 0;
        pointer-events: none;
        width: 100%;
      }

      .map-filters {
        display: none;
      }

      .report-contracts {
        display: block;
        height: 470px;
        margin: 0;
      }
    }

    .report-contracts {
      flex: 1;
    }
  }

  #map {
    height: 720px;

    @media only screen and (max-width: 899px) {
      height: 600px;
    }

    .map-icon {
      .map-icon-content {
        padding: 5px 0;
        line-height: 17px;
        text-align: center;
        color: #fff;
        border-radius: 12px;
        @include themify($themes) {
          background-color: themed('textSecondary');

          &.type-1 {
            background-color: themed('mapOld');
          }

          &.type-2 {
            background-color: themed('mapNew');
          }
        }
      }
    }

    .leaflet-popup-content-wrapper {
      @include themify($themes) {
        color: themed('textPrimary');
        background-color: themed('bgSecondary');
      }
    }

    .popup-container {
      .date {
        font-size: 14px;

        @media only screen and (max-width: 899px) {
          font-size: 12px;
        }
      }

      .location {
        font-size: 24px;
        text-align: center;

        @media only screen and (max-width: 899px) {
          font-size: 20px;
        }
      }

      .price {
        font-size: 36px;
        text-align: center;
        @include themify($themes) {
          color: themed('textSecondary');

          &.type-1 {
            color: themed('mapOld');
          }

          &.type-2 {
            color: themed('mapNew');
          }
        }

        @media only screen and (max-width: 899px) {
          font-size: 32px;
        }
      }

      .popup-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 5px;
      }

      .area {
        margin: 0 5px;
        padding: 5px 10px;
        font-size: 16px;
        text-align: center;
        color: #fff;
        background-color: #808080;
        border-radius: 20px;

        @media only screen and (max-width: 899px) {
          padding: 4px 8px;
          font-size: 14px;
        }
      }

      .subject {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        font-size: 14px;

        @media only screen and (max-width: 899px) {
          font-size: 12px;
        }
      }

      .subject-location {
        cursor: pointer;
      }

      a {
        @include themify($themes) {
          color: themed('main');
        }
      }
    }

    .marker-cluster {
      @include themify($themes) {
        color: #fff;
        background-color: themed('mapCluster');

        div {
          background-color: themed('mapCluster');
        }
      }

      &.marker-cluster-small {
        width: 32px !important;
        height: 32px !important;

        div {
          width: 24px;
          height: 24px;
          margin-left: 4px;
          margin-top: 4px;

          span {
            line-height: 24px;
          }
        }
      }

      &.marker-cluster-large {
        width: 48px !important;
        height: 48px !important;
        border-radius: 30px;

        div {
          width: 36px;
          height: 36px;
          margin-left: 6px;
          margin-top: 6px;
          border-radius: 20px;
        }

        span {
          line-height: 36px;
        }
      }

      div {
        font-family: inherit;
      }
    }
  }

  .full-screen-button {
    width: 100%;
    height: 100%;
  }
}

.leaflet-draw-toolbar {
  border-left: none !important;
  border-radius: 0 4px 4px 0 !important;

  .leaflet-draw-draw-polygon {
    -webkit-tap-highlight-color: transparent;

    &:after {
      content: 'Nacrtaj oblast pretrage';
      width: 132px;
      height: 30px;
      position: absolute;
      right: 100%;
      top: 0;
      padding-left: 8px;
      border: 2px solid rgba(0,0,0,0.2);
      border-right: 0;
      border-radius: 4px 0 0 4px;
      background-color: #fff;
      background-clip: padding-box;
    }

    &:hover {
      &:after {
        background-color: #f4f4f4;
      }
    }
  }
}

.leaflet-draw-actions {
  display: none !important;
}

.leaflet-error-draw-tooltip {
  display: none !important;
}

.leaflet-editing-icon {
  margin-left: -4px !important;
  margin-top: -4px !important;
  width: 8px !important;
  height: 8px !important;
  border-radius: 100%;
}
.theme-dark {
  .leaflet-layer,
  .leaflet-control-zoom-in,
  .leaflet-control-zoom-out,
  .leaflet-control-zoom-fullscreen,
  .leaflet-draw,
  .leaflet-control-attribution,
  .map-overlay,
  .remove-polygon {
    filter: invert(100%) hue-rotate(180deg) brightness(95%) contrast(90%);
  }
}
