@import '../../../styles/themeVariables.scss';

.main-select {
  .main-select-search {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    min-height: 82px;
  }

  .main-select-title {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    min-height: 82px;

    @media only screen and (max-width: 599px) {
      min-height: 64px;
    }

    .main-select-title-text {
      flex: 1;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding: 8px;
      white-space: nowrap;

      .region-text {
        margin: 0;
        font-size: 34px;
        font-weight: 500;
        @include themify($themes) {
          color: themed('textPrimary');
        }
        
        @media only screen and (max-width: 899px) {
          font-size: 22px;
        }
      }

      .month-text {
        display: flex;
        align-items: center;
        margin: 0;
        font-size: 34px;
        font-weight: 400;
        @include themify($themes) {
          color: themed('textSecondary');
        }

        @media only screen and (max-width: 899px) {
          font-size: 18px;
        }

        .dot-divider {
          font-size: 18px;
          @include themify($themes) {
            color: themed('textSecondary');
          }

          @media only screen and (max-width: 899px) {
            font-size: 12px;
          }
        }
      }

      .warning-text {
        flex-basis: 100%;
        display: flex;
        align-items: center;
        font-size: 20px;
        @include themify($themes) {
          color: themed('redLight');
        }

        svg {
          margin-right: 2px;
          font-size: 22px;
        }

        @media only screen and (max-width: 899px) {
          font-size: 12px;

          svg {
            font-size: 14px;
          }
        }
      }
    }
  }
}
