@import './styles/themeVariables.scss';

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: local(''),
  url('/fonts/Inter-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: local(''),
  url('/fonts/Inter-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src: local(''),
  url('/fonts/Inter-Black.ttf') format('truetype');
}

html {
  overflow-y: scroll;
}

.theme-dark {
  color-scheme: dark;
}

.app-container {
  @include themify($themes) {
    background-color: themed('bgPrimary');
  }

  .content {
    display: flex;
    flex-direction: column;
    max-width: 1020px;
    min-height: 100vh;
    margin: auto;
    font-family: 'Inter', 'Helvetica', 'Arial', sans-serif;
    font-size: 14px;

    .MuiButtonBase-root {
      text-transform: none;
    }
  }
}
