@import '../../styles/themeVariables.scss';

.logo-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-right: 10px;

  img {
    width: 34px;
    height: 34px;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }
}

.title-container {
  width: 100px;

  .header-title {
    font-size: 12px;
    font-weight: 700;
    cursor: pointer;
    max-width: 80px;
    -webkit-tap-highlight-color: transparent;
  }

  @media only screen and (max-width: 899px) {
    flex-basis: 100%;
  }
}

.themes-container {
  font-family: 'Inter', 'Helvetica', 'Arial', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;

  .theme-container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 80px;
    margin: 0 10px;
    text-align: center;
    color: $cn_black;
    background-color: $cn_white;
    border: 1px solid $cn_lightest_gray;
    border-radius: 8px;
    cursor: pointer;

    @media only screen and (max-width: 599px) {
      width: 100px;
      height:65px;
      margin: 0 5px 10px;
      font-size: 14px;
    }

    &.dark {
      color: $white;
      background-color: $cn_black;

      .theme-colors {
        .theme-color {
          border: 1px solid $white;
        }
      }
    }

    .theme-colors {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 5px 10px 0 0;

      .theme-color {
        height: 30px;
        width: 30px;
        margin-right: -10px;
        border: 1px solid $cn_lightest_gray;
        border-radius: 50%;

        @media only screen and (max-width: 599px) {
          height: 20px;
          width: 20px;
          margin-right: -8px;
        }
      }
    }
  }
}