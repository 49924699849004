$royal_blue: #3498db;
$medium_blue: #1976d2;
$yellow: #f1c40f;
$goldenrod: #daa520;
$sunflower_yellow: #ffc312;
$orange: #e67e22;
$olive_green: #8e9a00;
$teal: #1abc9c;
$light_deep_purple: #bb86fc;
$deep_purple: #8e44ad;
$lime_green: #2ecc71;
$chartreuse: #7dce94;
$crimson_red: #e74c3c;

$cn_blue: #124cb3;
$cn_navy_blue: #000066;
$cn_pink: #ff00ff;
$blueLight: #03a9f4;
$greenLight: #33cc66;
$redLight: #d9312b;
$black: #000;
$light_black: #0f0f0f;
$cn_black: #2c2c2e;
$cn_gray: #636366;
$cn_light_gray: #808080;
$cn_lightest_gray: #dfdfdd;
$cn_white: #f2f2f7;
$white: #fff;

$deep_purple: #8e44ad;
$medium_blue: #1976d2;
$goldenrod: #daa520;

$themes: (
  light: (
    main: #124cb3,
    new: #000066,
    old: #ff00ff,
    blueLight: #03a9f4,
    greenLight: #33cc66,
    redLight: #d9312b,
    textPrimary: #2c2c2e,
    textSecondary: #636366,
    bgPrimary: #f2f2f7,
    bgSecondary: #fff,
    border: #dfdfdd,
    headerPrimary: #000066,
    headerSecondary: #ff00ff,
    footerPrimary: #000066,
    footerSecondary: #ff00ff,
    footerTertiary: #fff,
    footerBorder: transparent,
    mapNew: #000066,
    mapOld: #ff00ff,
    mapCluster: rgba(18, 76, 179, .6),
    menuButtonText: #fff,
    menuButtonBg: #124cb3,
    cancelButtonText: #124cb3,
    cancelButtonBg: #fff,
    cancelButtonBorder: #124cb3,
    loading: #ff00ff,
  ),
  alternate: (
    main: $deep_purple,
    new: $medium_blue,
    old: $goldenrod,
    blueLight: #03a9f4,
    greenLight: #33cc66,
    redLight: #d9312b,
    textPrimary: #2c2c2e,
    textSecondary: #636366,
    bgPrimary: #f2f2f7,
    bgSecondary: #fff,
    border: #dfdfdd,
    headerPrimary: #000066,
    headerSecondary: #ff00ff,
    footerPrimary: #000066,
    footerSecondary: #fff,
    footerTertiary: #808080,
    footerBorder: transparent,
    mapNew: #1976d2,
    mapOld: #daa520,
    mapCluster: rgba(142, 68, 173, .6),
    menuButtonText: #fff,
    menuButtonBg: #124cb3,
    cancelButtonText: #2c2c2e,
    cancelButtonBg: #fff,
    cancelButtonBorder: #2c2c2e,
    loading: $deep_purple,
  ),
  dark: (
    main: $deep_purple,
    new: $medium_blue,
    old: $goldenrod,
    blueLight: #03a9f4,
    greenLight: #33cc66,
    redLight: #d9312b,
    textPrimary: #e9eaee,
    textSecondary: #808080,
    bgPrimary: #202124,
    bgSecondary: #2a2b2f,
    border: #606267,
    headerPrimary: #202124,
    headerSecondary: #e9eaee,
    footerPrimary: #202124,
    footerSecondary: #e9eaee,
    footerTertiary: #808080,
    footerBorder: #2c2c2e,
    mapNew: #1976d2,
    mapOld: #daa520,
    mapCluster: rgba(142, 68, 173, .6),
    menuButtonText: #fff,
    menuButtonBg: #202124,
    cancelButtonText: #fff,
    cancelButtonBg: #2c2c2e,
    cancelButtonBorder: #2c2c2e,
    loading: $deep_purple,
  ),
);

@mixin themify($themes) {
  @each $theme, $map in $themes {
    .theme-#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge(
          $theme-map,
            (
              $key: $value,
            )
        ) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}
