@import '../../../styles/themeVariables.scss';

.report-main {
  @media only screen and (max-width: 599px) {
    margin: 0 8px;
  }

  .title {
    display: flex;
    align-items: center;
    margin: 12px 0;
    padding-bottom: 12px;
    @include themify($themes) {
      color: themed('textPrimary');
      border-bottom: 1px solid themed('border');
    }

    h1, h2 {
      margin: 0 5px 0 0;
      font-size: 24px;
      font-weight: 500;
    }
  }

  .report-box {
    margin: auto;
    padding: 12px 15px;
    @include themify($themes) {
      color: themed('textPrimary');
      background-color: themed('bgSecondary');
      border: 1px solid themed('border');
    }
    border-radius: 5px;

    &.mb {
      margin-bottom: 8px;
    }
  
    .subtitle {
      margin: 0;
      font-size: 24px;
      font-weight: 500;
      @include themify($themes) {
        color: themed('main');

        &.new {
          color: themed('new');
        }

        &.old {
          color: themed('old');
        }
      }
    }

    .value-container {
      min-height: 94px;
      display: flex;
      align-items: center;
      overflow: hidden;
    
      .value {
        min-width: 140px;
        margin: 12px 0;
        font-size: 52px;
        font-weight: 700;

        @media only screen and (max-width: 599px) {
          font-size: 46px;
        }

        &.square-price {
          min-width: initial;
          font-size: 58px;

          @media only screen and (max-width: 599px) {
            font-size: 52px;
          }
        }
        
        .smaller {
          font-size: 36px;
          font-weight: 400;
        }

        .map-link {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          text-decoration: underline;

          div {
            @include themify($themes) {
              color: themed('textPrimary');
            }

            @media only screen and (max-width: 499px) {
              font-size: 18px;
            }
          }
        }
      }
  
      .value-chart {
        flex: 1;

        .recharts-wrapper {
          cursor: pointer !important;
        }
      }
    }

    .comparisons {
      min-height: 44px;

      .comparison-container {
        display: flex;
        align-items: center;
        font-size: 16px;
  
        .diff {
          display: flex;
          justify-content: center;
          align-items: center;

          @include themify($themes) {
            &.up {
              color: themed('greenLight');
            }

            &.down {
              color: themed('redLight');
            }

            &.flat {
              color: themed('blueLight');
            }
          }
        }

        .comparison-date {
          @include themify($themes) {
            color: themed('textSecondary');
          }
        }

        .bolder {
          font-weight: 500;
        }

        .smaller {
          font-size: 12px;
        }
      }
    }

    &.big {
      .value {
        display: flex;
        align-items: center;

        &.square-price {
          display: block;
        }
      }

      .subtitle {
        font-size: 48px;

        @media only screen and (max-width: 599px) {
          font-size: 40px;
        }
      }

      .value-container {
        flex-direction: column;
        align-items: initial;
        min-height: 241px;

        @media only screen and (max-width: 599px) {
          min-height: auto;
        }

        .value {
          margin: 15px 0;
          font-size: 96px;

          @media only screen and (max-width: 599px) {
            font-size: 75px;
          }

          &.square-price {
            font-size: 96px;

            @media only screen and (max-width: 599px) {
              font-size: 75px;
            }
          }

          .smaller {
            font-size: 44px;
          }
        }
      }

      .comparisons {
        .comparison-container {
          font-size: 28px;

          @media only screen and (max-width: 599px) {
            font-size: 20px;
          }

          .smaller {
            font-size: 18px;
          }
        }
      }

      .value-chart {
        height: 70px;
        margin-bottom: 25px;
      }
    }
  }
}
