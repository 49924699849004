@import '../../styles/themeVariables.scss';

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-top: auto;
  padding: 12px 8px 12px 16px;
  @include themify($themes) {
    background-color: themed('footerPrimary');
    border-top: 1px solid themed('footerBorder');
    border-bottom: 1px solid transparent;
  }
  font-size: 16px;

  @media only screen and (max-width: 599px) {
    font-size: 12px;
  }

  .date-updated {
    margin-bottom: 8px;
    @include themify($themes) {
      color: themed('footerTertiary');

      span {
        color: themed('footerSecondary');
      }
    }
  }

  .email {
    @include themify($themes) {
      color: themed('footerTertiary');

      a {
        text-decoration: inherit;
        color: themed('footerSecondary');
      }
    }
  }

  .social-icons {
    display: flex;
  }
}
