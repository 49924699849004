@import '../../styles/themeVariables.scss';

.donate-container {
  min-height: 758px;
  margin: 8px 0;
  padding: 20px 30px;
  font-size: 22px;
  line-height: 2;
  @include themify($themes) {
    color: themed('textPrimary');
    background-color: themed('bgSecondary');
    border: 1px solid themed('border');
  }
  border-radius: 5px;

  @media only screen and (max-width: 768px) {
    font-size: 14px;
  }

  @media only screen and (max-width: 499px) {
    margin: 8px;
  }

  .donate-method {
    display: flex;
    align-items: center;

    div {
      margin-right: 5px;
    }
  }

  .donate-money {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    .donate-button {
      width: 300px;
      margin: 10px;
      color: #fff;
      text-align: center;
      border-radius: 5px;
      cursor: pointer;
      
      @media only screen and (max-width: 768px) {
        width: 250px;
        font-size: 18px;
      }

      @include themify($themes) {
        &.paypal {
          background-color: themed('new');
        }

        &.cofee {
          background-color: themed('old');
        }
      }

      a {
        display: block;
        width: 100%;
        height: 100%;
        color: inherit;
        text-decoration: inherit;
      }
    }

    .donate-crypto {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 500px;
      margin: 15px auto;
      padding: 20px 5px;
      text-align: center;
      color: #636366;
      border-radius: 5px;
    
      .qr {
        width: 120px;
        height: 120px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        &.eth {
          background-image: url("eth-qr.png");
        }
        
        &.btc {
          background-image: url("btc-qr.png");
        }
      }
    
      .eth-adress {
        font-size: 18px;
        font-weight: 500;
        word-break: break-all;
    
        @media only screen and (max-width: 768px) {
          font-size: 13px;
        }
      }
    }
  }
}
