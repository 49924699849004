@import '../../../styles/themeVariables.scss';

.report-charts {
  .hide-graph {
    display: none;
  }

  .chart-container {
    padding: 16px;
    border-radius: 5px;
    @include themify($themes) {
      background-color: themed('bgSecondary');
      border: 1px solid themed('border');
    }

    @media only screen and (max-width: 599px) {
      margin: 0 8px;
      padding: 8px;
    }

    .chart-label {
      display: flex;
      align-items: center;
      @include themify($themes) {
        color: themed('textPrimary');
      }

      h1, h2 {
        margin: 0 5px 0 0;
        font-size: 24px;
        font-weight: 500;

        @media only screen and (max-width: 599px) {
          font-size: 18px;
        }
      }
    }

    .recharts-legend-wrapper {
      top: 5px !important;
    }

    .recharts-default-legend {
      text-align: right !important;
      font-weight: 500;
    }
  }
}
